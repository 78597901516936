<template>
  <ais-instant-search :search-client="searchClient" index-name="tlig_posts_messages">

    <!-- ADVANCED SEARCH-->
    <div class="flexBetween">
      <div @click="openAdvSearch" class="advanced-search-link">Advanced Search</div> 
      <ais-powered-by class="search-by" />
    </div>
    <div id="advsearch" class="flex align-items-center marginBottom10 invisible marginLeft5">
      <div class="marginTBAuto height17 search-font-size-14 marginLeft5 ">
        Sorted by:&nbsp;
      </div>
      <ais-sort-by class="sort-by"
        :items="[
          { value: 'tlig_posts_messages', label: 'Date Descending' },
          { value: 'tlig_posts_messages_asc', label: 'Date Ascending' },
        ]"
      />
    </div>

    <ais-configure
      :attributesToSnippet="['content:25']"
      :hits-per-page.camel="20"
      :filters="'language:en'"
    >
      <!-- AUTOCOMPLETE -->
      <ais-autocomplete>
        <template v-slot="{ currentRefinement, indices, refine }">
          <div class="flexBetween inputContainer">
            <input class="search-box w-full"
              type="search"
              :autofocus="true"
              :value="currentRefinement"
              placeholder="Search in the Messages"
              ref="searchInput"
              @input="refine($event.currentTarget.value)"
              @keydown.up.prevent="highlightPrevious()"
              @keydown.down.prevent="highlightNext(indices[0].hits.length)"
              @keydown.enter="goToMessage(indices)"
            ><!--  &nbsp;&nbsp;
            <ais-powered-by class="search-by" /> -->
          </div> 

          <ais-refinement-list 
            attribute="content" 
            operator="and"
          />

          <div v-if="currentRefinement" class="z-index-10 search-font-base w-screen search-max-width">
            <div class="rounded-8 shadow-lg overflow-hidden">
              <div v-for="section in indices" :key="section.objectID" class="relative grid gap-12 search-padding-1 bg-white"
                :class="{ 'displayNone' : section.hits.length == 0 }">
                 
                 <div>section : {{ section.objectID }}</div>

                <router-link to="#" v-for="(hit, index) in section.hits" :key="hit.objectID" class="card-container"  
                            :class="{ 'bg-blue-100' : isCurrentIndex( index ) }">

                  <div class="px-2" 
                    @mouseover="highlightIndex = index"
                    @click="goToMessage(hit)">
                    <ais-highlight attribute="post_title" attributesToSnippet="[post_title]" :hit="hit" class="block uppercase gras search-title-color" />
                    <div class="search-font-size-14">
                      {{ hit.post_date_formatted }}
                    </div>
                    <ais-snippet attribute="content" :hit="hit" class="block search-color search-font-size-14" />
                  </div>

                </router-link>

              </div>
            </div>
            <ais-pagination
              :show-previous="true"
              :show-next="true"
              :show-last="true"
              :padding="3"
            />
          </div>

        </template>  
      </ais-autocomplete>
    </ais-configure>
  </ais-instant-search>
</template>

<script>
import { mapState } from 'vuex';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';
import $ from 'jquery';

export default {
  name: "search-page",
  data() {
    return {
      searchClient: algoliasearch(
        'QNT9ZAU1KS',
        '53176efad0b6701132752c6258fb58e9'
      ),
      highlightIndex: -1,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      advSearch: true,
    };
  },
  computed: {
    ...mapState(['db', 'messagesIds']),
  },
  methods: {
    openAdvSearch() {
      this.advSearch = !this.advSearch;
      if ( this.advSearch ) {
        $("#advsearch").css({'height': '0', 'visibility': 'hidden'}); }
      else {
        $("#advsearch").css({'height': '40px', 'visibility': 'visible'});
      }
    },
    dateMessage(dateM) {
      return dateM
      //let jour = dateM.substr(8, 2);
      //let mois = dateM.substr(5, 2);
      //let annee = dateM.substr(0, 4);
      //return jour + ' ' + this.months[mois - 1] + ' ' + annee;
    },
    highlightPrevious() {
      if (this.highlightIndex > 0) {
        this.highlightIndex -= 1
      }
    },
    highlightNext(resultsCount) {
      if (this.highlightIndex < resultsCount - 1) {
        this.highlightIndex += 1
      }
    },
    isCurrentIndex(index) {
      return index === this.highlightIndex
    },
    goToMessage(hit) {

      // for the moment return
/* eslint-disable */

      return null;

      if (this.highlightIndex > -1 ) {
        let dateTemp = hit.post_date_formatted.split(' ')
        let moisTemp = dateTemp[0]
        let anneeTemp = dateTemp[2]
        let dateJourTemp = dateTemp[1].split(',')
        let jourTemp = dateJourTemp[0]
        let dateSearch = anneeTemp + "-" + moisTemp + "-" + jourTemp

        this.getOneMessage(dateSearch)
            .then(response => {
              this.$router.push({ name: 'MessagesDisplay', params: { messageItem: JSON.stringify(response) } });
              console.log('response du message du search = ', response)
        })
        console.log('le hit = ', hit)
      }
    },
    async getOneMessage(dateQuery) {
      return new Promise((resolve) => {

        console.log('db = ', this.$store.state.db)
        let transaction = this.$store.state.db.transaction("messages", "readonly");
        let messages = transaction.objectStore("messages"); // obtenir le magasin d'objets pour opérer dessus
        let messageIndex = messages.index("date_message");

        let request = messageIndex.get(dateQuery);

        transaction.oncomplete = () => {
          resolve(request.result);
        };
        
      });
    },
  },
};
</script>

<style>
body {
  font-family: sans-serif;
  padding: 1em;
}
</style>