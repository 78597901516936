import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Display from '../views/Display.vue'
import Messages from '../views/Messages.vue'
import MessagesDisplay from '../views/MessagesDisplay.vue'
import Random from '../views/Random.vue'
import Link from '../views/Link.vue'
import Search from '../views/Search.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/display/:postId',
    name: 'Display',
    component: Display,
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages
  },
  {
    path: '/messages/:messageItem',
    name: 'MessagesDisplay',
    component: MessagesDisplay,
  },
{
    path: '/random',
    name: 'Random',
    component: Random
  },
  {
    path: '/link',
    name: 'Link',
    component: Link
  },
 {
    path: '/search',
    name: 'Search',
    component: Search
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
