<template>
  <div class="post" :style="{ '--newFontSize' : this.$store.state.newFont }">
    <ToolbarTop :back="this.$t('homeLabel')" :title="this.$t('prayersLabel')"></ToolbarTop>

    <div v-if="this.content == ''"><img src="/img/wheel.gif"></div>
    <div v-else class="article mb100">
      <div v-html="this.content"></div>
    </div>

    <div v-if="pdf" id="pdf-embed">
      <vue-pdf-embed :source="url" />
    </div>

  </div>

</template>

<script>
import { mapState } from 'vuex';
import ToolbarTop from '@/components/ToolbarTop.vue';
import VuePdfEmbed from 'vue-pdf-embed';
import $ from 'jquery';

export default {
  props: { id: Array, pageTitle: String },
  name: "display-pdf",
  components: { VuePdfEmbed, ToolbarTop },
	data() {
		return {
      linguaCorrente: null,
      content: "",
      pdf: false,
    }
	},
  computed: {
    ...mapState(['postDisplay'])
},
  watch: {
    postDisplay () {
      this.content = decodeHtml(this.$store.state.postDisplay[0].content.rendered); // eslint-disable-line
      //this.$store.commit( 'SET_TITLE_DISPLAY', decodeHtml(this.$store.state.postDisplay[0].title.rendered) )

      this.$nextTick(function () { // wait that all DOM is rendered
        console.log('tippy in app')
        tippy('[data-tippy-content]', { // eslint-disable-line
          theme: 'rougeFonce',
          trigger: 'click',
          animation: 'scale',
          interactive: true,
        }); 
      })
    }
  },
  methods: {
  },
  mounted() {
    // Read the Post
    this.$store.commit( 'SET_WP_POSTID', this.$route.params.postId )
    this.$store.dispatch("getPost");
    this.$store.commit( 'SET_TXT_NAV_CENTER', true )

  },
  updated() {
    var charSizeDefault = localStorage.charSize ? localStorage.charSize : 2;
    this.$store.commit( 'SET_NEWFONT', this.$store.state.itemSizes[charSizeDefault].size )
    $('.chevron').css('visibility', 'hidden');
  },
} 
</script>
