<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "random-page",
  computed: {
    ...mapState(['randomAdr'])
  },
  created() { 
    console.log('entre Random created et currentDir = ', this.$store.state.currentDir);
    this.$store.dispatch("getRandom");
  },
  watch: {
    // when random message has been selected
    randomAdr () {
      console.log('randomADR = ', this.$store.state.randomAdr)
      window.open( this.$store.state.randomAdr, '_blank' );
      history.back()
      //this.$router.replace({ path: "/" + this.$store.state.currentDir });
      //this.$router.back('Home');
    }
  },
}
</script>

<style>
#expRedPlace {
  display: inline-block;
  width: var( --WidthA )
}
</style>