<template>
  <div class="post" :style="{ '--newFontSize' : this.$store.state.newFont }">
    <ToolbarTop :back="this.$t('listingLabel')" :title="stringLabel"></ToolbarTop>

      <!-- Display a single Message -->
      <div class="marginBottom100 padding20" v-if="afficherMessages">
        <div class="titleMessage" v-html="messageAAfficher.titre"></div>
        <div v-html="messageAAfficher.content"></div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToolbarTop from '@/components/ToolbarTop.vue';
import $ from 'jquery';

export default {
  name: "messages-display",
  props: { messageItem: String },
  components: { ToolbarTop },
	data() {
		return {
      afficherMessages: false,
      messageJson: {},
      messageAAfficher: {
        "titre": String,
        "content": String,
      },
      stringLabel: "",
      prevID: null,
      currentID: 0,
      nextID: null,
    }
	},
  computed: {
    ...mapState(['allMessages', 'txtNavCenter']),
  },
  methods: {
    async getOneMessage(dateQuery) {
      return new Promise((resolve) => {

        let transaction = this.$store.state.db.transaction("messages", "readonly");
        let messages = transaction.objectStore("messages"); // obtenir le magasin d'objets pour opérer dessus
        let messageIndex = messages.index("date_message");

        let request = messageIndex.get(dateQuery);

        transaction.oncomplete = () => {
          resolve(request.result);
        };
        
      });
    },
    prev() {
      if (this.prevID) {
        this.searchPrevNext(this.allMessages[this.prevID].id);
        this.messageAAfficher.titre = decodeHtml(this.allMessages[this.currentID].title); // eslint-disable-line
        this.messageAAfficher.content = decodeHtml(this.allMessages[this.currentID].content); // eslint-disable-line
        $('html,body').scrollTop(0);
        this.$nextTick(function () { // wait that all DOM is rendered
          console.log('createdfootnotes in messages')
          createFootnotes(); // eslint-disable-line
          updateDOM(); // eslint-disable-line
        })
      }
    },
    next() {
      if (this.nextID) {
        this.searchPrevNext(this.allMessages[this.nextID].id);
        this.messageAAfficher.titre = decodeHtml(this.allMessages[this.currentID].title); // eslint-disable-line
        this.messageAAfficher.content = decodeHtml(this.allMessages[this.currentID].content); // eslint-disable-line
        $('html,body').scrollTop(0);
        this.$nextTick(function () { // wait that all DOM is rendered
          console.log('createdfootnotes in messages')
          createFootnotes(); // eslint-disable-line
          updateDOM(); // eslint-disable-line
        })
      }
    },
    searchPrevNext(searchID) {
      for (var i = 0; i < this.allMessages.length; i++) {
        if (this.allMessages[i].id == searchID) {
          this.currentID = i.toString();
          if (this.allMessages[i + 1] == undefined) { // next null
            this.nextID = null }
          else {
            this.nextID = (i + 1).toString(); 
          }
          if (this.allMessages[i - 1] == undefined) { // prev null
            this.prevID = null; }
          else {
            this.prevID = (i - 1).toString();
          }
          break;
        }
      }
      this.setChevrons();
      console.log('prev', this.prevID, ' current', this.currentID, ' next', this.nextID)
    },
    setChevrons() {
      console.log('setChevrons')
      if (this.nextID) { // next null
        $('.texteMilieu .pi-chevron-right').css('visibility', 'visible'); }
      else {
        $('.texteMilieu .pi-chevron-right').css('visibility', 'hidden');
      }
      if (this.prevID) { // next null
        $('.texteMilieu .pi-chevron-left').css('visibility', 'visible'); }
      else {
        $('.texteMilieu .pi-chevron-left').css('visibility', 'hidden'); 
      }
    }
  },
  mounted() {
    this.messageJson = JSON.parse(this.$route.params.messageItem)
    console.log('messageJson = ', this.messageJson)
    console.log('allMessages = ', this.allMessages)
    this.stringLabel = `<div class="toolbarLabel">` + this.$t('messagesLabel') + `</div>`;

    // Search the position of the message to retrieve the previous and next message

    this.afficherMessages = true;
    this.messageAAfficher.titre = decodeHtml(this.messageJson.title); // eslint-disable-line
    this.messageAAfficher.content = decodeHtml(this.messageJson.content); // eslint-disable-line

    this.searchPrevNext(this.messageJson.id)
    this.setChevrons();

    this.$nextTick(function () { // wait that all DOM is rendered
      console.log('createdfootnotes in messages')
      createFootnotes(); // eslint-disable-line
      updateDOM(); // eslint-disable-line
    })
    console.log('messageslagel', this.$t('messagesLabel'))
    this.$store.commit( 'SET_TITLE_DISPLAY', this.$t('messagesLabel') )
    this.$store.commit( 'SET_TXT_NAV_CENTER', true )
    console.log('txtNavCenter 0 ', this.$store.state.txtNavCenter)
    $('html,body').scrollTop(0);
  },
} 
</script>

<style>
.post { margin-top: 36px !important; }
.padding20 { padding: 20px; }

.titleMessage { font-family: "Papyrus"; font-size: 30px; font-weight: bold; line-height: 38px; }
h2 { margin-top: 5px !important; color: #8b3801; font-weight: bold; }
.jc { font-family: "Trebuchet MS", Verdana, sans-serif;
    color: #511a06;
    font-weight: 600;
    text-align: justify; }
.vassula { font-family: "Trebuchet MS", Verdana, sans-serif;
    font-style: italic;
    margin-left: 20px;
    color: #482020; }
.footref sup:hover {
    color: #ff3300;
    cursor: pointer; }
.footref sup, .footnotes div a:first-child {
    padding: 1px 3px;
    background-color: rgb(255, 224, 97);
    border-radius: 3px;
    color: black;
    font-size: 60%;
    font-weight: bold; }
.footnotes {
    font-weight: normal; }
.footnotes p { margin: 0px; }
[id^="fn"] {
    font-family: "Trebuchet MS", Verdana, sans-serif;
    font-size: 90%;
    margin-top: 10px;
    line-height: 1.2em; }

/*******************
/** TIPPY TOOLTIP **
/*******************/
.tippy-tooltip {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #482020;
}
.tippy-content a {
  color: #fae280;
}
.tippy-tooltip.tlig-theme .tippy-backdrop {
  background-color: #482020;
}
/* theme */
.tippy-popper {
  z-index: 99999 !important;
}
.tippy-popper[x-placement^="right"] .light-theme .tippy-arrow {
  border-right: 8px solid #fcf8e3 !important;
}
.tippy-popper[x-placement^="left"] .light-theme .tippy-arrow {
  border-left: 8px solid #fcf8e3 !important;
}
.tippy-popper[x-placement^="top"] .light-theme .tippy-arrow {
  border-top: 8px solid #fcf8e3 !important;
}
.tippy-popper[x-placement^="bottom"] .light-theme .tippy-arrow {
  border-bottom: 8px solid #fcf8e3 !important;
}
.tippy-tooltip.light-theme {
  background-color: #fcf8e3 !important;
}
</style>