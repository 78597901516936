<template>
  <div></div>
</template>

<script>
export default {
  name: "link-page",
  created() {
    console.log('route linkAdr = ', this.$store.state.linkAdr );
    window.open( this.$store.state.linkAdr, '_blank' );
    history.back()
  },
}
</script>